import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from "./CSS/login.module.css"
import Cookies from 'universal-cookie';
import { GridLoader } from "react-spinners";

let Login = () => {
  let [user, setUser] = useState("");
  let [pass, setPass] = useState("");
  let [CUIT, setCUIT] = useState("");
  let [loginSpinner, setLoginSpinner] = useState(false)
  let [alerta, setAlerta] = useState("");
  let history = useHistory();
  let cookies = new Cookies();
  let token = cookies.get('reparto_userToken');
  if (token) {
      history.push("/home");
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    document.getElementById("login").disabled = true;
    setLoginSpinner(true);
    if ([user, pass, CUIT].includes("")) {
      setAlerta("Hay campos vacios");
      setTimeout(() => {
        setAlerta({});
      }, 2500);
    } else {
      try {
        let url = "https://apiproid.azurewebsites.net/api/loginreparto";
        let data  = await axios.post(url, { CUIT, user, pass });
        if (data.data === '{"resp":"fail"}') {
          document.getElementById("login").disabled = false;
          setLoginSpinner(false);
          setAlerta("Usuario invalido");
          setTimeout(() => {
            setAlerta({});
          }, 3500);
        } else {
          if (data.data.length > 15) {
            cookies.set('reparto_userToken', data.data.slice(9, -2), { path: '/', expires: new Date(Date.now()+5182000)});
            setLoginSpinner(false);
            document.getElementById("login").disabled = false;
            history.push("/home");
          };
        };
      } catch (error) {
        console.log(error)
        setAlerta(error?.response?.data)
        setTimeout(() => {
          setAlerta({});
        }, 3500);
      };
    };
  };

  return (
    <div>
      <br /> <br />
      <div>
        <form onSubmit={handleSubmit} className={styles.loginForm}>
          <h2>Logística</h2>
          <hr />
          {alerta.length ? <h3 style={{color: "red"}}> {alerta} </h3> : null}
            <div className="input-group">
              <input type="number" placeholder="CUIT" id="cuit" name="cuit" className="form-control" autoComplete="on" value={CUIT} onChange={(e) => setCUIT(e.target.value)}/>
            </div>
          <br />
            <div>
              <div className="input-group">
                <input type="text" placeholder="Usuario" id="Usuario" name="Usuario" className="form-control" autoComplete="on" value={user} onChange={(e) => setUser(e.target.value)}/>
              </div>
            </div>
          <div>
            <br />
            <div className="input-group">
                <input type="password" placeholder="Contraseña" id="pass" name="pass" className="form-control" autoComplete="on" value={pass} onChange={(e) => setPass(e.target.value)}/>
            </div>
          </div>
            <br />
            <div className="ini">
              <button id="login" type="submit" className="btn btn-outline-info" onSubmit={handleSubmit}>Iniciar sesión</button>
              <br /> <br />
              {loginSpinner ? 
                <GridLoader 
                  color="#36d7b7"
                  size={15}
                  aria-label="Loading Spinner"
                /> 
              :
              null
              }
            </div>
          <br />
        </form>
      </div>
    </div>
  );
};



export default Login;