import React, { useEffect } from "react";
import styles from "./CSS/tracking.module.css"
import Nav from './Nav';
import Cookies from 'universal-cookie';
import GridLoader  from "react-spinners/ClipLoader";
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getLists, lockStart, selectViewTrack } from "../store/actions";
import axios from "axios";

export default function ViewOnly() {
    let cookies = new Cookies();
    let history = useHistory();
    let trackId  = useParams();
    let dispatch = useDispatch();
    let currentLat = useSelector(state => state.currentLat);
    let currentLng = useSelector(state => state.currentLng);
    let currentViewTrack = useSelector(state => state.currentViewTrack);
    let currentTrack = useSelector(state => state.currentTrack);
    let toComplete = currentViewTrack?.paradas?.filter(t => t.finalizado === false);
    let completed = currentViewTrack?.paradas?.filter(t => t.finalizado === true);
    let token = cookies.get('reparto_userToken');
    let url = `https://apiproid.azurewebsites.net/api/reparto?id=${token}`

    let checkStarted = () => {
        setTimeout(function(){
            if (currentViewTrack && currentViewTrack.inciado) {
                history.push("/track/" + currentViewTrack.reparto)
            }
        },700)
    };

    if (!token) {
        history.push("/");
    };

    if (!currentViewTrack) {
        dispatch(getLists(token));
        dispatch(selectViewTrack(trackId.id));
    };
    
    let handleBegin = (reparto, id) => {
        let trackBegun = {
            id: id,
            lat: currentLat,
            lon: currentLng,
            inciado: true,
            finalizado: false
        };
        dispatch(lockStart(currentViewTrack?.id));
        axios.put(url, trackBegun);
        history.push("/track/" + reparto);
    };
    
    useEffect(() => {
        checkStarted();
        dispatch(selectViewTrack(trackId.id));
    }, []);

    useEffect(() => {
        checkStarted();
    });

    return (
    <div>

        <div>
            <Nav/>
        </div>

        <div>
            {!currentViewTrack ?
            <div style={{"marginTop": "150px"}}>
                <GridLoader 
                    color="#36d7b7"
                    size={150}
                    aria-label="Loading Spinner"
                /> 
            </div>  
            :
            <div className={styles.trackWindow}>
                <h3>Hola {currentViewTrack?.usuario}</h3>
                    {currentTrack.id ? null : <button className="btn btn-success"  data-toggle="modal" data-target="#beginModal"> Iniciar Reparto </button>}
                        <div>
                        {currentViewTrack ? 
                            toComplete?.map(c =>  {                 
                                return (
                                    <div className={styles.indivClient} key={c.id}>
                                            <span className={styles.clientProp}>{c.nombre} </span>
                                            <span className={styles.clientProp}>{c.direccion} </span>
                                            <span className={styles.clientProp}>{c.horario} </span>
                                    </div>
                                );
                                })
                            :
                                <h4> 
                                    No quedan clientes que visitar 
                                </h4> 
                            }
                        </div>

                        {completed?.length ?
                        <div>
                        <hr />
                            <div disabled="true">
                                    <h4> Paradas visitadas </h4>
                                    {completed.length ?
                                        completed?.map(c =>  {                 
                                            return (
                                                <div key={c.id}>
                                                    <div className={styles.indivClientVisited} key={c.id} disabled={true}>
                                                            <span className={styles.clientProp}>{c.nombre} </span>
                                                            <span className={styles.clientProp}>{c.direccion} </span>
                                                            <span className={styles.clientProp}>{c.horario} </span>
                                                    </div>
                                                </div>
                                            );
                                            })
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            :
                            null
                        }
            </div>
            }
        </div>

        <div className="modal fade" id="beginModal" tabIndex="-1" role="dialog" aria-labelledby="beginModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Inicio del recorrido</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        Confirmar el comienzo del recorrido
                </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-dark" data-dismiss="modal">Cancelar</button>
                        <button type="button" className="btn btn-outline-info" data-dismiss="modal" onClick={() => {handleBegin(currentViewTrack.id, currentViewTrack.reparto)}}>Confirmar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  );
};