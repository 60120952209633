import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./CSS/tracking.module.css"
import Cookies from 'universal-cookie';
import GridLoader  from "react-spinners/ClipLoader";
import { Link, useHistory, useParams } from 'react-router-dom';
import { getLists, resetTrack, selectTrack } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";

export default function Tracking() {
    let cookies = new Cookies();
    let trackId  = useParams();
    let dispatch = useDispatch();
    let history = useHistory();
    let token = cookies.get('reparto_userToken');
    let currentTrack = useSelector(state => state.currentTrack);
    
    let checkToken = () => {
        setTimeout(function(){
            if (!token) {
                history.push("/");
            };        
        },150)
    };

    if (!token) {
        history.push("/");
    };

    let checkContent = () => {
        setTimeout(function(){
            if(!currentTrack ){
                history.push("/")
            };    
        },2000)
    };

    let toComplete = currentTrack?.paradas?.filter(t => t.finalizado === false);
    let completed = currentTrack?.paradas?.filter(t => t.finalizado === true);
    console.log(toComplete)
    let url = `https://apiproid.azurewebsites.net/api/reparto?id=${token}`

    let delayedRefresh = () => {
        setTimeout(window.location.reload(), 1350);
    };

    function handleComplete() {
        let trackCompleted = {
            id: currentTrack.id,
            completado: true,
            lat: "",
            lon: "",
            inciado: true,
            finalizado: true
        };
        currentTrack.finalizado = true;
        currentTrack.completado = true;
        axios.put(url, trackCompleted);
        dispatch(resetTrack());
        history.push("/home");
        delayedRefresh();
    };

    useEffect(() => {
        dispatch(selectTrack(trackId.id));
    }, []);

    useEffect(() => {
        checkContent();
        checkToken();
    });

  return (
    <div>
        {!currentTrack ?
        <div style={{"marginTop": "150px"}}>
            <GridLoader 
                color="#36d7b7"
                size={150}
                aria-label="Loading Spinner"
            /> 
        </div>  
        :
        <div className={styles.trackWindow}>
            <h3>Hola {currentTrack?.usuario}</h3>
                    
                    {toComplete?.length == 0 && completed.length  ? 
                        <button style={{margintTop:"35px" ,height:"75px"}} className="btn btn-outline-success" data-toggle="modal" data-target="#completedModal">
                            Finalizar recorrido
                        </button>
                    : 
                        <div className={styles.propsList}>
                            <span> Clientes a visitar </span>
                            <hr />
                        </div>
                    }

                    <div>
                    {currentTrack ? 
                        toComplete?.map(c =>  {                 
                            return (
                                <div className={styles.indivClient} key={c.id}>
                                    <Link to={"/order/" + c.clien}>
                                        <span className={styles.clientProp}>{c.nombre} </span>
                                        <span className={styles.clientProp}>{c.direccion} </span>
                                        <span className={styles.clientProp}>{c.horario} </span>
                                    </Link>
                                </div>
                            );
                            })
                        :
                            <h4> 
                                No quedan clientes que visitar 
                            </h4> 
                        }
                    </div>

                    {completed?.length ?
                    <div>
                    <hr />
                        <div disabled="true">
                                <h4> Paradas visitadas </h4>
                                {completed.length ?
                                    completed?.map(c =>  {                 
                                        return (
                                            <div key={c.id}>
                                                <div className={styles.indivClientVisited} key={c.id} disabled="true">
                                                        <span className={styles.clientProp}>{c.nombre} </span>
                                                        <span className={styles.clientProp}>{c.direccion} </span>
                                                        <span className={styles.clientProp}>{c.horario} </span>
                                                </div>
                                            </div>
                                        );
                                        })
                                    :
                                    null
                                }
                            </div>
                        </div>
                        :
                        null
                    }

                    <div className="modal fade" id="completedModal" tabIndex="-1" role="dialog" aria-labelledby="completedModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Recorrido Finalizado</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    Confirmar la finalización del recorrido
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-dark" data-dismiss="modal">Cancelar</button>
                                    <button type="button" className="btn btn-outline-info" data-dismiss="modal" onClick={handleComplete}>Confirmar</button>
                                </div>
                            </div>
                        </div>
                    </div>

        </div>
        }
    </div>
  );
};
