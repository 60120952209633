import React from 'react'
import Nav from './Nav'
import Tracking from './Tracking'
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';


export default function Home() {
  let history = useHistory();
  let cookies = new Cookies();
  let token = cookies.get('reparto_userToken');
  if (!token) {
      history.push("/");
  };

  return (
    <div>
      <Nav/>
      <Tracking/>
    </div>
  )
}
