import React, { useState } from 'react';
import styles from "./CSS/tracking.module.css";
import Nav from './Nav';
import Cookies from 'universal-cookie';
import { Link, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLists, lockStart } from '../store/actions';
import GridLoader  from "react-spinners/ClipLoader";

export default function TrackSelect() {
    let cookies = new Cookies();
    let token = cookies.get('reparto_userToken');
    let history = useHistory();
    let dispatch = useDispatch();
    let [loading, setLoading] = useState(true)
    let trackList = useSelector(state => state.trackList);
    let currentTrack = useSelector(state => state.currentTrack);
    let started = trackList?.filter(t => t.inciado === true);
    let toComplete = trackList?.filter(t => t.completado === false);
    let completed = trackList?.filter(t => t.completado === true);

    let trackCheck = () => {
        if (currentTrack?.id) {
            history.push("/track/" + currentTrack.reparto)
        };
    };

    let activeCheck = () => {
        if(started[0]?.id){
            history.push("/track/" + started[0]?.reparto)
            dispatch(lockStart(started[0]?.id));
        };
    };

    if (!token) {
        history.push("/");
    };

    let cancelLoading = () => {
        setLoading(false)
    };
    setTimeout(cancelLoading, 3000);

    useEffect(() => {
        dispatch(getLists(token));
        activeCheck();
        trackCheck();
      },[]);

  return (
    <div>
        
        <div>
            <Nav/>
        </div>

        <div>
            {loading ? 
                <div style={{"marginTop": "150px"}}>
                    <GridLoader 
                        color="#36d7b7"
                        loading= {loading}
                        size={150}
                        aria-label="Loading Spinner"
                    /> 
                </div> 
                :
            (<div>
                {started.length ? 
                    <div>
                        {started.map(c =>  {                 
                            return (
                                <div className={styles.indivClient} key={c.reparto}>
                                    <Link to={"/view/" + c.reparto}>
                                        <span className={styles.clientProp}>Reparto: {c.reparto} </span>
                                        <span className={styles.clientProp}>Conductor: {c.usuario} </span>
                                    </Link>
                                </div>
                            );
                            })
                        }
                    </div>
                    :
                    <div>
                        <h2>Repartos</h2>
                            {toComplete.length && toComplete[0].id ? 
                                toComplete.map(c =>  {                 
                                    return (
                                        <div className={styles.indivClient} key={c.reparto}>
                                            <Link to={"/view/" + c.reparto}>
                                                <span className={styles.clientProp}>Reparto: {c.reparto} </span>
                                                <span className={styles.clientProp}>Conductor: {c.usuario} </span>
                                            </Link>
                                        </div>
                                    );
                                    })
                                :
                                    <h4 style={{width: "85%", margin: "auto" }}> No quedan repartos pendientes </h4> 
                                }
                    </div>
                }
            </div>)
            }
        </div>

        <div>
                {completed?.length ? 
                    completed.map(c =>  {                 
                        return (
                            <div className={styles.indivClientVisited} key={c.reparto} disabled="true">
                                    <span className={styles.clientProp}>Reparto: {c.reparto} </span>
                                    <span className={styles.clientProp}>Conductor: {c.usuario} </span>
                            </div>
                        );
                        })
                    :
                        null
                    }
        </div>
    </div>
  )
}
