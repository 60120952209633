import { DETAIL, GET_CLIENT_LIST, LATITUDE, LOCK_TRACK, LONGITUDE, RESET_TRACK, SELECT_TRACK, SELECT_VIEW_TRACK } from "../constants";

const initialState = {
    trackList: [],
    filteredTrackList: [],
    currentTrack: {},
    currentViewTrack: {},
    currentDetail: [],
    currentLat: null,
    currentLng: null,
    };

    export default function reducer(state=initialState, action){
        switch(action.type){
            case GET_CLIENT_LIST:
                return {
                  ...state,
                  trackList: action.payload,
                  filteredTrackList: action.payload,
                };
            case DETAIL:
                let detailSelect = state.trackList.map(c => c.paradas.filter(p => p.clien == action.payload));
                return {
                    ...state,
                    currentDetail: detailSelect,
                };
            case SELECT_TRACK:
                let trackSelect = state.trackList.filter(c => c.reparto == action.payload);
                return {
                    ...state,
                    currentTrack: trackSelect[0],
                };
            case SELECT_VIEW_TRACK:
                let trackView = state.trackList.filter(c => c.reparto == action.payload);
                return {
                    ...state,
                    currentViewTrack: trackView[0],
                };
            case LOCK_TRACK:
                let trackBegins = state.trackList.filter(c => c.id == action.payload);
                trackBegins[0].inciado = true;
                console.log(trackBegins);
                return {
                    ...state,
                    currentViewTrack: trackBegins[0],
                    currentTrack: trackBegins[0],
                };
            case LATITUDE:
                return {
                    ...state,
                    currentLat: action.payload,
                };
            case LONGITUDE:
                return {
                    ...state,
                    currentLng: action.payload,
                };
            case RESET_TRACK:
                return {
                    ...state,
                    currentTrack: [],
                };
            default:
                return state;
        };
    };

    