import React, { useState } from 'react';
import {
  GoogleMap,
  DirectionsRenderer,
  LoadScript,
} from '@react-google-maps/api';
import styles from "./CSS/track.module.css"
import { useSelector } from 'react-redux';

export default function Map(direccion, direccion2) {
    let localidad = useSelector(state => state.currentDetail[0]);
    let [map, setMap] = useState(({}));
    console.log(map);
    let [directionsResponse, setDirectionsResponse] = useState(null);
    let [distance, setDistance] = useState('');
    let [duration, setDuration] = useState('');
    let currentLat = useSelector(state => state.currentLat);
    let currentLng = useSelector(state => state.currentLng);
    let [alerta, setAlerta] = useState("");

    let destinationRef = direccion.direccion  ? direccion.direccion + " " + localidad[0].locaclidad : direccion2.direccion2 + " " +  localidad[0].locaclidad;

    async function calculateRoute() {
      if (currentLng === '' || currentLat=== "" || destinationRef === '') {
        setAlerta("No se pudo definir la ruta dinámica");
        return;
      };
      try {
        // eslint-disable-next-line no-undef
        let directionsService = new google.maps.DirectionsService()
        let results = await directionsService.route({
          // eslint-disable-next-line no-undef
          origin: new google.maps.LatLng({lat: currentLat, lng: currentLng}),
          destination: destinationRef,
          // eslint-disable-next-line no-undef
          travelMode: google.maps.TravelMode.DRIVING,
        })
        setDirectionsResponse(results);
        setDistance(results.routes[0].legs[0].distance.text);
        setDuration(results.routes[0].legs[0].duration.text);
      } catch (error) {
        window.alert("Directions request failed due to " + error)
      };
    };

    let onUnmount = React.useCallback(function callback(map) {
      setMap(null)
    }, []);
  
    let containerStyle = {
      marginTop: "15px",
      margin: "auto",
      padding: "12px",
      width: "93%",
      height: "400px",
      border: "2px grey solid",
      borderRadius: "5px",
    };
    
    let center = {
      lat: currentLat ?  currentLat : -34.6,
      lng: currentLng ?  currentLng : -58.5,
    };

    let handleMaps = () => {
      window.open(`https://maps.google.com?q=${destinationRef}`);
    };

  return (
    <div className={styles.mapTrack}>
        {alerta && 
        <div>
          <h4 style={{color: "red", margin: "auto", marginBottom: "4px"}}> {alerta} </h4>
          <button style={{margin: "auto", marginBottom: "4px"}} type="button" className="btn btn-success" onClick={handleMaps}> Abrir en Google Maps </button>
        </div>

        }
            <LoadScript googleMapsApiKey={"AIzaSyA3lWxEccObPjSjSo4oqIwCGuMTJDqVQkg"} libraries={["places"]}>
              <GoogleMap
                center={center}
                zoom={11}
                mapContainerStyle={containerStyle}
                options={{
                  zoomControl: true,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: true,
                }}
                onLoad={map => {
                  let bounds = new window.google.maps.LatLngBounds();
                  map.fitBounds(bounds);
                  setMap(map);
                  calculateRoute();
                }}
                onUnmount={onUnmount}
                >
                  {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                  )}
              </GoogleMap>

              {Object.keys(map).length ? 
                <div style={{marginTop:"8px", fontSize:"22px"}}>
                  <button
                      isround="true"
                      onClick={() => {
                        map.panTo(center)
                        map.setZoom(13)
                      }}
                  > Centrar </button>
                  <br />
                  <hr />
                  <span>Distancia: {distance} </span>
                  <hr />
                  <span>Tiempo para llegar: {duration} </span>
                  <hr />
                  {alerta.length ? null : <button type="button" className="btn btn-success" onClick={handleMaps}> Abrir en Google Maps </button>}
                </div>
              :
              null
              }
            </LoadScript>
    </div>
  )
};