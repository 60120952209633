import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from "./store/index"
import {Provider} from 'react-redux'
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from 'react-router-dom';
import PWAPrompt from 'react-ios-pwa-prompt'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
          <PWAPrompt promptOnVisit={3} timesToShow={3} copyClosePrompt="Quizas luego" permanentlyHideOnDismiss={false}/>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();

reportWebVitals();
