import axios from "axios";
import { GET_CLIENT_LIST, DETAIL, LATITUDE, LONGITUDE, SELECT_TRACK, RESET_TRACK, SELECT_VIEW_TRACK, LOCK_TRACK } from "../constants";

export function getLists (token){
    return async function (dispatch){
      return axios.get(`https://apiproid.azurewebsites.net/api/reparto?id=${token}`)
      .then((response) => {
        dispatch({
          type: GET_CLIENT_LIST,
          payload:response.data,
        });
      });
    };
  };

  export function selectTrack(trackId){
    return {
        type: SELECT_TRACK,
        payload: trackId
    };
  };
  
  export function filterDetail(id){
    return {
        type: DETAIL,
        payload: id
    };
  };

  export function localLatitude(number){
    return {
        type: LATITUDE,
        payload: number
    };
  };

  export function localLongitude(number){
    return {
        type: LONGITUDE,
        payload: number
    };
  };

  
  export function selectViewTrack(trackId){
    return {
        type: SELECT_VIEW_TRACK,
        payload: trackId
    };
  };

  export function lockStart(trackId){
    return {
        type: LOCK_TRACK,
        payload: trackId
    };
  };

  export function resetTrack(){
    return {
        type: RESET_TRACK,
    };
  };

