import './App.css';
import Details from './components/Detail';
import Home from './components/Home';
import Login from './components/Login';
import ViewOnly from './components/ViewOnly';
import TrackSelect from './components/TrackSelect';
import Cookies from 'universal-cookie';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { getLists, localLatitude, localLongitude } from './store/actions';
import NotFound from './components/NotFound';
import axios from 'axios';

function App() {
  let cookies = new Cookies();
  let token = cookies.get('reparto_userToken');
  let [latitude, setLatitude] = useState("");
  let [longitude, setLongitude] = useState("");
  let dispatch = useDispatch();
  let allTracks = useSelector(state => state.trackList);

  if(!allTracks){
    dispatch(getLists(token));
  };
  
  let geoPost = () => {
      let url = `https://apiproid.azurewebsites.net/api/location?id=${token}`;
      console.log("posteo", latitude, longitude);
      if(latitude && longitude){
        axios.post(url, {lat: latitude, lon: longitude}) ;
      };
  };

  let geoSave = () => {
    getLocation();
    if(latitude && longitude) {
      dispatch(localLatitude(latitude));
      dispatch(localLongitude(longitude));
    };
  };

  useEffect(() => {
    getLocation();
    dispatch(localLatitude(latitude));
    dispatch(localLongitude(longitude));
  },[]);

  useEffect(() => {
    let interval = setInterval(geoPost, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [geoPost]);
  
  useEffect(() => {
    let interval2 = setInterval(geoSave, 15000);
    return () => {
      clearInterval(interval2);
    };
  }, [geoSave]);


  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setPosition);
    } else { 
      alert("Se requiere la ubicación para que funcione la aplicación");
      navigator.geolocation.getCurrentPosition(setPosition);
    };
  };

  function setPosition(position) {
    setLatitude(position.coords.latitude); 
    setLongitude(position.coords.longitude); 
  };


  return (
    <div className="App">
      <Switch>

        <Route exact path="/">
          <Login/>
        </Route>

        <Route exact path="/home">
          <TrackSelect/>
        </Route>

        <Route exact path="/track/:id">
          <Home/>
        </Route>

        <Route exact path="/view/:id">
          <ViewOnly/>
        </Route>

        <Route exact path="/order/:id">
          <Details/>
        </Route>

        <Route path="*" component={NotFound}/>

      </Switch>
    </div>
  );
};

export default App;

