import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { getLists } from '../store/actions';

export default function Nav() {
  let history = useHistory();
  let cookies = new Cookies();
  let token = cookies.get('reparto_userToken');
  if (!token) {
      history.push("/");
  };

  let currentTrack = useSelector(state => state.currentTrack);
  let currentViewTrack = useSelector(state => state.currentViewTrack);

  let handleLogout= () => {
    cookies.remove("reparto_userToken", {path: "/"});
    history.push("/");
  };

  return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <span className="navbar-brand"> Logística </span>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mr-auto">

              {currentTrack !== undefined && currentTrack.id ? 
                <div>
                  <li className="nav-item" style={{marginTop: "4px"}}>
                      <Link to={`/track/${currentTrack?.id}`}className="nav-link"> Reparto </Link>
                    </li>
                </div>      
                : 
                null     
              }

              {currentViewTrack?.id !== undefined && currentViewTrack?.id && (currentTrack === undefined || !currentTrack.id)  ? 
                <div>
                  <li className="nav-item" style={{marginTop: "4px"}}>
                      <Link to={`/view/${currentViewTrack?.id}`}className="nav-link"> Visualzar Reparto </Link>
                    </li>
                </div> 
                  : 
                  null     
              }

              {currentTrack === undefined === undefined && currentViewTrack === undefined  ? 
                <div>
                  <li className="nav-item" style={{marginTop: "4px"}}>
                    <Link to={`/home`}className="nav-link"> Recorrido </Link>
                  </li>
                </div> 
                : 
                null
              }

              {token ? 
                <div>
                  <li className="nav-item" style={{minWidth:"85%"}}>
                    <button style={{marginTop: "5px", width:"95%"}} className="btn btn-outline-danger" onClick={() => {handleLogout()}}> Salir </button>
                  </li>
                </div> 
                : 
                null
              }
              
          </ul>
        </div>
    </nav>
  )
};