import React from 'react'
import Cookies from 'universal-cookie';
import Nav from './Nav';
import styles from "./CSS/tracking.module.css"
import GridLoader  from "react-spinners/ClipLoader";
import Map from './Map';
import PhoneCall from "../components/Assets/phone-logo.png";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { filterDetail, getLists } from '../store/actions';
import { useEffect } from 'react';
import { useState } from "react";

export default function Details() {
    let cookies = new Cookies();
    let token = cookies.get('reparto_userToken');
    let currentTrack = useSelector(state => state.currentTrack);
    let currentLat = useSelector(state => state.currentLat);
    let currentLng = useSelector(state => state.currentLng);
    let clientDetail = useSelector(state => state.currentDetail[0]); //condicional para refrescar
    let [loading, setLoading] = useState(true);
    let [commentary, setCommentary] = useState("");
    let history = useHistory();
    let order  = useParams();
    let dispatch = useDispatch();
    let url = `http://apiproid.azurewebsites.net/api/reparto?id=${token}`

    if (!token) {
        history.push("/");
    };
    
    function checkRefresh() {
        setTimeout(function(){
            if (!currentTrack.id){
                dispatch(getLists());
                history.push("/home");
                console.log("1");
                window.location.reload();
            };
            
            if (!clientDetail[0].id && currentTrack.id){
                dispatch(getLists());
                history.push("/track/" + currentTrack.reparto);
                console.log("2")
            };
    
            if (!clientDetail?.length && !currentTrack.id){
                dispatch(getLists(token));
                console.log("3")
                history.push("/home");
                window.location.reload();
            };
        }, 500)
    };

    function handleCommentary(e) {
        setCommentary(e.target.value);
    };

    function cancelLoading(){
        setTimeout(function(){
            setLoading(false);
        }, 1500);
    };
    
    let handleDelivery = () => {
        let delivered = {
            id: clientDetail[0].id,
            lat: currentLat,
            lon: currentLng,
            completado: true,
            comentario: ""
        };
        clientDetail[0].completado = true
        clientDetail[0].finalizado = true
        axios.post(url, delivered);
        history.push(`/track/${currentTrack.reparto}`);
        window.location.reload();
    };
    
    let handleNotDelivered = () => {
        let notDelivered = {
            id: clientDetail[0].id,
            lat: currentLat,
            lon: currentLng,
            completado: false,
            comentario: commentary
        };
        clientDetail[0].finalizado = true
        console.log(notDelivered)
        axios.post(url, notDelivered);
        history.push(`/track/${currentTrack.reparto}`);
        window.location.reload();
    };
    
    useEffect(() => {
        dispatch(filterDetail(order.id));
        cancelLoading();
      },[]);

      useEffect(() => {
        checkRefresh();
      });

   return (
        <div>
            <Nav/>
            {!clientDetail ?    
            <div style={{"marginTop": "150px"}}>
                <GridLoader 
                    color="#36d7b7"
                    loading={loading}
                    size={150}
                    aria-label="Loading Spinner"
                /> 
            </div>  
            :
            <div>
                <div className={styles.clientDetailPage}>
                    <button className="btn btn-outline-danger" onClick={() => history.goBack()} style={{height:"60px", width:"150px", fontSize:"35px"}}> Salir </button>
                    <div className={styles.propsList}>
                        <span> Nombre de cliente: </span>
                        <span className={styles.clientPropPage}>{clientDetail[0].nombre}</span>
                        <hr />
                        <span>Total a pagar:</span>
                        <span className={styles.clientPropPage}>${clientDetail[0].Total}</span>
                        <hr/>
                        <span> Dirección: </span>
                        <span className={styles.clientPropPage}>{clientDetail[0].direccion}</span>

                        {clientDetail[0].direccion2 !== " " ? 
                            <span className={styles.clientPropPage}>{clientDetail[0].direccion2}</span>
                            : 
                            null
                        }

                        <hr />
                        {clientDetail[0].locaclidad ? 
                            <div>
                                <span> Localidad: </span>
                                <span className={styles.clientPropPage}>{clientDetail[0].locaclidad}</span>
                                <hr />
                            </div>
                        : 
                        null
                        }

                        {clientDetail[0].horario ? 
                            <div>
                                <span> Horario: </span>
                                <span className={styles.clientPropPage}>{clientDetail[0].horario}</span>
                                <hr />
                            </div>
                        : 
                        null
                        }
                        
                        {clientDetail[0].telefono.length > 2 ? 
                            <div> 
                                <span> Telefono </span>
                                <span className={styles.clientPropPage}>{clientDetail[0].telefono}</span>
                                <img src={PhoneCall} alt="Llamar" style={{height: "50px"}} onClick={() => window.open(`tel:${clientDetail[0].telefono}`)} /> 
                                <hr />
                            </div>
                            : 
                            null
                            }
                    </div>

                    {clientDetail[0].direccion || clientDetail[0].direccion2 ? 
                        <div>
                            <Map direccion={clientDetail[0].direccion} direccion2={clientDetail[0].direccion2}/>
                        </div>
                    : 
                    null
                    }

                    <hr />

                    <div>
                        <span>
                            <button style={{height: "75px", width:"65%"}} className="btn btn-outline-success" data-toggle="modal" data-target="#deliveredModal">
                                Entregado
                            </button>
                        </span>
                        <br/>
                        <hr/>
                        <span>
                        <button style={{height: "75px", width:"65%"}} type="button"className="btn btn-outline-danger" data-toggle="modal" data-target="#commentaryModal">
                                No entregado
                            </button>
                        </span>
                    </div>

                    <br />

                    {clientDetail[0].comprobantes.length ? 
                        <div className="container">
                            <button type="button" className="btn btn-primary btn-lg btn-block" data-toggle="collapse" data-target="#demo">Comprobantes</button>
                            <div id="demo" className="collapse">
                            {clientDetail[0].comprobantes.map(c =>  {                 
                                return (
                                <div className={styles.indivClient} key={c.clien}>
                                    <span className={styles.clientProp}>Fecha: {c.fecha} </span>
                                    <span className={styles.clientProp}> Vencimiento: {c.vence} </span>
                                    <span className={styles.clientProp}>Comprobante: {c.comprobante} </span>
                                    <span className={styles.clientProp}>Nº: {c.numero}</span>
                                    <span className={styles.endClientProp}> Total: ${c.total}</span>
                                </div>
                            );
                            })}
                            </div>
                        </div>
                    : 
                    null
                    }

                    <div className="modal fade" id="commentaryModal" tabIndex="-1" role="dialog" aria-labelledby="commentaryModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Paquete no entregado</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    Motivo:
                                    <hr />
                                    <input type="text" onChange={handleCommentary} style={{width:"90%"}}/>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-dark" data-dismiss="modal">Cancelar</button>
                                    <button type="button" className="btn btn-outline-info" data-dismiss="modal" onClick={handleNotDelivered}>Enviar</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="deliveredModal" tabIndex="-1" role="dialog" aria-labelledby="deliveredModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Paquete entregado</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    Confirmar la entrega del paquete
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-dark" data-dismiss="modal">Cancelar</button>
                                    <button type="button" className="btn btn-outline-info" data-dismiss="modal" onClick={handleDelivery}>Confirmar</button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        }
        </div>
    );
};
